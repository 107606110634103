import { apiMethods } from "data/api.constants";
import { CREATE_AFFLIATE_DISCOUNT } from "./AddAffliateDiscount.api";

export const postAddAffliateDiscount = async (dataProvider, payload) => {
  return await dataProvider.custom_request(
    CREATE_AFFLIATE_DISCOUNT,
    apiMethods.POST,
    payload
  );
};
