import React from "react";
import {
  ThemeProvider,
  creatorToolTheme,
  Modal,
} from "@my-scoot/component-library-legacy";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Schedule from "@material-ui/icons/Schedule";
import Clear from "@material-ui/icons/Clear";
import module_style from "./PayoutStatusModal.module.css";
import { is_empty } from "utils/validations";
import { Button } from "@my-scoot/component-library-legacy";
import {
  PAYMENT_STATUS,
  PAYMENT_STATUS_KEYS,
} from "features/Payment/constants/Payment.constants";
import { getRAUrlParams } from "utils/urlUtils";
import { usePayment } from "features/AffiliateMarketing/utils/usePayment";

export const PayoutStatusModal = () => {
  const { getOrderStatus, handleRetryPayment } = usePayment();

  const [showModal, setShowModal] = React.useState(false);
  const [payoutStatus, setPayoutStatus] = React.useState(null);
  const [orderId, setOrderId] = React.useState("");

  React.useEffect(() => {
    (async () => {
      const order_id = getRAUrlParams()?.get("exly_order_id");
      if (!is_empty(order_id)) {
        setOrderId(order_id);
        const orderData = await getOrderStatus(order_id);

        setPayoutStatus(orderData?.status);
        setShowModal(true);
      }
    })();
  }, []);

  if (is_empty(payoutStatus)) {
    return null;
  }

  const handleClose = () => {
    setShowModal(false);
    setPayoutStatus(null);
    const newUrl = window.location.href.split("?")[0];
    window.history.replaceState({}, "", newUrl);
  };

  const renderBody = () => {
    switch (payoutStatus) {
      case PAYMENT_STATUS[PAYMENT_STATUS_KEYS.FAILED].value: {
        return (
          <div
            className={`d-flex flex-column justify-content-center align-items-center`}
          >
            <div className={`${module_style.fail_header} my-4`}>
              Payment failed!
            </div>

            <Cancel
              className={`${module_style.payment_svg} ${module_style.fail_icon}`}
            />

            <div className={`${module_style.description} mt-4 text-center`}>
              We&apos;re sorry, but we were unable to process your payment. Any
              amount debited will be refunded in 2-5 business days.
            </div>

            <div className={`${module_style.line} mt-4`}></div>

            <div className={`my-2 row w-100`}>
              <div className={`col-6`}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </div>
              <div className={`col-6`}>
                <Button
                  onClick={() => handleRetryPayment({ orderId })}
                  fullWidth
                >
                  Retry payment
                </Button>
              </div>
            </div>
          </div>
        );
      }

      case PAYMENT_STATUS[PAYMENT_STATUS_KEYS.SUCCESS].value: {
        return (
          <div
            className={`d-flex flex-column justify-content-center align-items-center`}
          >
            <Clear
              onClick={handleClose}
              className={`${module_style.close_modal} pointer`}
            />

            <div
              style={{
                backgroundColor:
                  PAYMENT_STATUS[PAYMENT_STATUS_KEYS.SUCCESS].background_color,
              }}
              className={`${module_style.svg_container} mt-4`}
            >
              <CheckCircle
                style={{
                  fill: PAYMENT_STATUS[PAYMENT_STATUS_KEYS.SUCCESS].color,
                }}
                className={`${module_style.payment_svg}`}
              />
            </div>

            <div
              style={{
                color: PAYMENT_STATUS[PAYMENT_STATUS_KEYS.SUCCESS].color,
              }}
              className={`${module_style.header} mt-4`}
            >
              Payment Successful!
            </div>

            <div className={`${module_style.description} mt-4 text-center`}>
              An invoice will be sent in your email.
              <br />
            </div>

            <div className={`${module_style.line} mt-4`}></div>
          </div>
        );
      }

      case PAYMENT_STATUS[PAYMENT_STATUS_KEYS.PROCESSING].value:
      case PAYMENT_STATUS[PAYMENT_STATUS_KEYS.INITIATED].value: {
        return (
          <div
            className={`d-flex flex-column justify-content-center align-items-center`}
          >
            <Clear
              onClick={handleClose}
              className={`${module_style.close_modal} pointer`}
            />

            <div
              style={{
                backgroundColor:
                  PAYMENT_STATUS[PAYMENT_STATUS_KEYS.INITIATED]
                    .background_color,
              }}
              className={`${module_style.svg_container} mt-4`}
            >
              <Schedule
                style={{
                  fill: PAYMENT_STATUS[PAYMENT_STATUS_KEYS.INITIATED].color,
                }}
                className={`${module_style.payment_svg}`}
              />
            </div>

            <div
              style={{
                color: PAYMENT_STATUS[PAYMENT_STATUS_KEYS.INITIATED].color,
              }}
              className={`${module_style.header} mt-4`}
            >
              Payment under processing
            </div>

            <div className={`${module_style.description} mt-4 text-center`}>
              Oh, looks like your payment is taking some time.
            </div>

            <div className={`${module_style.line} mt-4`}></div>

            <div className={`${module_style.disclaimer} mt-4 mb-4`}>
              Don’t worry, we’ll notify you once your payment is successfully
              through. Although, if the payment fails, then any amount that has
              been debited from your account will be refunded to you within 24
              hrs.
            </div>
          </div>
        );
      }

      default: {
        return <></>;
      }
    }
  };

  return (
    <ThemeProvider theme={creatorToolTheme}>
      <Modal
        customFooter={<></>}
        customHeader={<></>}
        modalPaperClassName={`${module_style.payment_modal}`}
        open={showModal}
      >
        <>{renderBody()}</>
      </Modal>
    </ThemeProvider>
  );
};
