import React from "react";
import { Avatar } from "@my-scoot/component-library-legacy";
import {
  CURRENCY_SYMBOL_MAP,
  RUPEE_SYMBOL,
} from "features/Common/modules/Currency/constants/Currency.constants";
import { is_empty } from "utils/validations";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { roundOff } from "utils/Utils";

export const PayoutAmountField = ({ record }) => {
  const isDesktop = useDesktopMediaQuery();
  return (
    <div className="d-flex align-items-center">
      {!record.currency && !isDesktop ? (
        <Avatar className="mr-2" label={RUPEE_SYMBOL} processInitials={false} />
      ) : null}
      <span>
        {is_empty(record.currency)
          ? RUPEE_SYMBOL
          : CURRENCY_SYMBOL_MAP[record.currency]}
        {roundOff(record.payout_amount, 2)}
      </span>
    </div>
  );
};
