import { is_empty } from "features/Common/utils/common.utils";

export const getInitialData = (affliateDetail = {}) => {
  let phone_number = affliateDetail.country_code + affliateDetail.phone_number;

  let affiliate_listing_map;
  if (!is_empty(affliateDetail?.affiliate_listing_map)) {
    affiliate_listing_map = affliateDetail?.affiliate_listing_map.map(
      (item) => item.uuid
    );
  }

  const modifiedData = {
    ...affliateDetail,
    phone_number,
    country_code: affliateDetail.country_code,
    affiliate_listing_map: affiliate_listing_map || [],
    account_full_name: affliateDetail?.account_details?.full_name,
    affliate_full_name: affliateDetail.full_name,
  };
  return modifiedData;
};
