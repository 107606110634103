import { PayoutAmountField } from "../modules/PayoutAmountField/PayoutAmountField";
import {
  getPayoutAmountConfig,
  getPayoutDateConfig,
  getReceiptURLConfig,
  getPayoutStatusConfig,
  getMinPayoutAmountFilterConfig,
  getPayoutStatusFilterConfig,
  getPayoutDateStartRangeFilterConfig,
  getMaxPayoutAmountFilterConfig,
  getPayoutDateEndRangeFilterConfig,
  PAYOUT_STATUS_OPTIONS,
  form_data,
} from "./payout.constants";
import React from "react";

export const getAffilatesPayoutColumnConfig = () => [
  {
    ...getPayoutAmountConfig(),
    renderColumn: (record) => <PayoutAmountField record={record} />,
  },
  getPayoutDateConfig(),
  getReceiptURLConfig(),
  getPayoutStatusConfig(),
];
export const payoutTableChipsFormatter = () => (filter, value) => {
  switch (filter) {
    case "status":
      return null;
    case "min_payout_amount":
      return `Minimum Payout Amount: ${value}`;
    case "max_payout_amount":
      return `Max Payout Amount: ${value}`;
    case "payout_status": {
      const status = PAYOUT_STATUS_OPTIONS?.find(
        (item) => item.value === value
      );
      return `Payout status: ${status?.label}`;
    }
    case "payout_date__start_range":
      return `${form_data.payout_date__start_range.label}: ${value}`;
    case "payout_date__end_range":
      return `${form_data.payout_date__end_range.label}: ${value}`;
    case "affliate_name":
      return `Affiliate Name: ${value}`;
    default:
      return undefined;
  }
};
export const payoutFilterConfig = [
  getMinPayoutAmountFilterConfig(),
  getMaxPayoutAmountFilterConfig(),
  getPayoutStatusFilterConfig(),
  getPayoutDateStartRangeFilterConfig(),
  getPayoutDateEndRangeFilterConfig(),
];
