import React from "react";
import ExlyImage from "features/Common/modules/ExlyImage/ExlyImage";
import moduleStyle from "./CopyAffiliateLinkButton.module.css";
import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { logError } from "utils/error";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { isRequestSuccessful } from "utils/Utils";
import { apiMethods } from "data/api.constants";
import { GET_OFFERING_LINK_API } from "features/AffiliateMarketing/constants/Affliates.api";
import { dataProvider } from "data";

export const CopyAffiliateLinkButton = ({ record }) => {
  const { affiliateUuid } = useGetAffiliateUuid();
  const { notify } = useNotifications();

  const copyAffiliateLink = async () => {
    try {
      const response = await dataProvider.custom_request(
        GET_OFFERING_LINK_API,
        apiMethods.POST,
        {
          affiliate_uuid: affiliateUuid,
          listing_uuid: record.offering_uuid,
        }
      );
      if (isRequestSuccessful(response?.status)) {
        const link = response?.data?.shareable_link;

        if (navigator && navigator.clipboard) {
          navigator.clipboard.writeText(link);
          notify("Link Copied!", notification_color_keys.success);
        }
      }
    } catch (error) {
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.success
      );
      logError({
        error,
        occuredAt:
          "src/features/AffiliateMarketing/modules/OfferingWiseReport/modules/CopyAffiliateLinkButton/CopyAffiliateLinkButton.jsx",
        when: "copyAffiliateLink",
      });
    }
  };

  return (
    <div
      onClick={copyAffiliateLink}
      className={`pointer d-flex align-items-center mx-2`}
    >
      <ExlyImage path="assets/images/affiliates/link.svg" alt="copy link" />
      <span className={`ml-2 ${moduleStyle.text}`}>Copy Affiliate link</span>
    </div>
  );
};
