import {
  COMMISSION_PAYOUT_STATUS,
  COMMISSION_PAYOUT_STATUS_KEYS,
} from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import React from "react";
import moduleStyle from "./PayoutStatusField.module.css";
import { is_empty } from "utils/validations";
import CachedIcon from "@material-ui/icons/Cached";
import { ActionField } from "common/Components/TableFields/TableFields";
import { usePayment } from "features/AffiliateMarketing/utils/usePayment";

export const PayoutStatusField = ({
  record = {},
  source,
  hideRetry = false,
}) => {
  const { handleRetryPayout } = usePayment();
  const payout_status = record[source];

  if (is_empty(payout_status)) return null;

  return (
    <div className={`d-flex align-items-center`}>
      <div
        className={`${moduleStyle.payoutStatusText} fit_content`}
        style={{
          backgroundColor: COMMISSION_PAYOUT_STATUS[payout_status].bgColor,
        }}
      >
        {COMMISSION_PAYOUT_STATUS[payout_status].label}
      </div>
      {payout_status ===
        COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.failed].value &&
        !hideRetry && (
          <div className={`ml-2 d-flex align-items-center`}>
            <CachedIcon className={moduleStyle.cachedIcon} />
            <ActionField
              onClick={() => handleRetryPayout({ payoutUuid: record.uuid })}
              label="Retry"
              className={moduleStyle.retry}
            />
          </div>
        )}
    </div>
  );
};
