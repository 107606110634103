import React, { useMemo, useState } from "react";
import ExlyModal from "common/Components/ExlyModal";
import { Form } from "react-final-form";
import styles from "./UpdateAffliateDetailsModal.module.css";
import { Tabs } from "@my-scoot/component-library-legacy";
import { useDispatch, useSelector } from "react-redux";
import { AddAffliateForm } from "features/AffiliateMarketing/modules/AddAffiliate/modules/AddAffliateForm/AddAffliateForm";
import { getInitialData } from "./utils/UpdateAffliateFormContent.utils";
import BankDetails from "../AffliateDetail/components/BankDetailsForm";
import { DETAILS_TAB } from "../AffliateDetail/constants/detail.constants";
import useAffiliateProfile from "../AffliateDetail/utils/useAffliateProfile";
import { useSetCommissionFormInitialValues } from "features/AffiliateMarketing/utils/useSetCommissionFormInitialValues";
import { EXLY_AFFILIATE_MARKETING__SET_COMMISSION_INITIAL_VALUES } from "features/AffiliateMarketing/redux/Affliates.actions";

export const UpdateAffliateFormContent = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("basic_details");
  const affliateDetail = useSelector(
    (state) => state.affiliateMarketing.affliateDetail
  );

  const initialValues = useMemo(
    () => getInitialData(affliateDetail),
    [affliateDetail]
  );
  useSetCommissionFormInitialValues({ initialValues });

  const handleClose = (form) => {
    onClose();
    form && form.reset();
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_COMMISSION_INITIAL_VALUES,
      payload: {},
    });
  };
  const { updateAffiliateProfile, processing } = useAffiliateProfile({
    handleClose,
  });

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderForm = () => {
    if (selectedTab === DETAILS_TAB.basic_details.value) {
      return <AddAffliateForm disableFields />;
    } else {
      return <BankDetails />;
    }
  };

  return (
    <Form
      onSubmit={(values) => updateAffiliateProfile(values, handleClose)}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting }) => (
        <ExlyModal
          open={open}
          onClose={() => {
            handleClose(form);
          }}
          title={"Affiliate details"}
          onPrimaryBtnClick={handleSubmit}
          primaryBtnText={processing ? "..." : "Update"}
          primaryBtnProps={{
            type: "submit",
            disabled: submitting,
            loading: submitting,
          }}
          modal_props={{
            modalPaperClassName: styles.desktopModalPaper,
          }}
          mobile_modal_props={{
            paperClassName: styles.modalPaper,
            escapeKeyDownClose: true,
          }}
          className={`p-0`}
        >
          <div className={`px-3`}>
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              tabs={Object.values(DETAILS_TAB)}
              tabVariant="outlined"
              classes={{ flexContainer: styles.tabWrapper }}
            />

            <div>{renderForm()}</div>
          </div>
        </ExlyModal>
      )}
    />
  );
};
