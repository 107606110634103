import React from "react";
import moduleStyle from "../../NewAffliateRequest.module.css";
import ExlyImage from "features/Common/modules/ExlyImage/ExlyImage";

export const AutoApprovedEmptyState = () => {
  return (
    <div className={`d-flex flex-column align-items-center`}>
      <ExlyImage
        path="assets/images/affiliates/check_circle.png"
        alt="checkIcon"
        className={`${moduleStyle.checkIcon} mt-4 mb-3`}
      />
      <div className={`${moduleStyle.autoApprovedTitle} mb-3 text-center`}>
        All Affiliates will be automatically added!
      </div>
      <div
        className={`${moduleStyle.autoApprovedDescription} mb-3 text-center`}
      >
        The affiliates will be automatically added since you have turned
        <br />
        off approval for affiliates. A mail will be sent to the
        <br />
        affiliates, that they’ve been approved and the details to login
      </div>
    </div>
  );
};
