import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { postAddAffliateDiscount } from "../constants/AddAffliateDiscount.apiCalls";
import { dataProvider } from "data";
import { useRefresh } from "react-admin";
import { isRequestSuccessful } from "utils/Utils";
import { useShowDiscountCodesModal } from "../../AffliateDiscount/utils/useShowDiscountCodesModal";
import { useState } from "react";

export const useAddAffliateDiscount = () => {
  const { notify } = useNotifications();
  const refresh = useRefresh();
  const { showDiscountCodeSuccessModal, closeDiscountCodesModal } =
    useShowDiscountCodesModal();
  const [processing, setProcessing] = useState(false);

  const handleAddAffliateDiscountCode = async (values) => {
    try {
      if (processing) return;
      setProcessing(true);
      values.usage_limit = parseInt(values.usage_limit) || 0;
      values.value = parseInt(values.value) || 0;
      let payload = { ...values };
      const response = await postAddAffliateDiscount(dataProvider, payload);
      if (isRequestSuccessful(response?.status)) {
        notify(
          "Discount code successfully added!",
          notification_color_keys.success
        );
        refresh();
        closeDiscountCodesModal();
        showDiscountCodeSuccessModal({
          value: true,
          record: { ...response?.data },
        });
      }
    } catch (error) {
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
      logError({
        error,
        when: "while creating affiliate discount",
        occuredAt:
          "src/features/AffiliateMarketing/modules/Discount/utils/useAddAffliateDiscount.js",
      });
    } finally {
      setProcessing(false);
    }
  };

  return {
    handleAddAffliateDiscountCode,
    processing,
  };
};
