import React, { useEffect } from "react";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ExlyTable from "common/Components/ExlyTable";
import moduleStyle from "./NewAffliateRequest.module.css";
import SuccessConfirmationModal from "../../components/ConfirmationModal";
import { AFFLIATE_ENTITY_TYPE } from "features/AffiliateMarketing/constants/Affliates.constants";
import {
  newAffilatesRequestColumnConfig,
  newAffliateFilterConfig,
  pendingTableChipsFormatter,
} from "features/AffiliateMarketing/modules/NewAffiliateRequest/constants/newAffliateColumn.config";
import SelectionCard from "features/BulkActions/modules/SelectionCard/SelectionCard";
import useBulkActions from "features/BulkActions/utils/hooks/useBulkActions";
import { useListController } from "react-admin";
import { DEFAULT_PER_PAGE } from "constants/numbers";
import {
  BULK_ACTION_TABLES,
  BULK_ACTION_TYPES,
} from "features/BulkActions/BulkActions.constants";
import { AffiliateManagementHeader } from "../AffiliateManagementHeader/AffiliateManagementHeader";
import {
  AFFILIATE_MANAGEMENT_TABS,
  AFFLIATE_STATUS,
} from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import { useAffiliateStatus } from "./utils/useAffiliateStatus";
import { AffiliateStatusField } from "./modules/AffliateStatusField/AffiliateStatusField";
import { useBulkAffiliateRequestsActions } from "./utils/useBulkAffiliateRequestsActions";
import { useShowAddAffiliateModal } from "../AddAffiliate/utils/useShowAddAffiliateModal";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import { affiliate_management_back_action } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import { useAffiliateSettingsModal } from "../AffiliateSettings/modules/AffiliateSettingsModal/utils/useAffiliateSettingsModal";
import { is_empty } from "features/Common/utils/common.utils";
import ExlyLoader from "ui/modules/ExlyLoader";
import { useDispatch } from "react-redux";
import { EXLY_AFFILIATE_MARKETING__FETCH_AFFILIATE_SETTINGS } from "features/AffiliateMarketing/redux/Affliates.actions";
import { AutoApprovedEmptyState } from "./modules/AutoApprovedEmptyState/AutoApprovedEmptyState";
import { AutoApprovedStrip } from "./modules/AutoApprovedStrip/AutoApprovedStrip";
import { useCheckAffiliateAccessible } from "features/AffiliateMarketing/utils/useCheckAffiliateAccessible";
import { AffiliateManagementMobileHeader } from "../AffiliateManagementHeader/AffiliateManagementMobileHeader";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";

export const NewAffiliateRequest = withComponentLibraryTheme((props) => {
  useCheckAffiliateAccessible();
  const dispatch = useDispatch();
  const {
    showApprovalSuccessModal,
    showRejectionConfirmationModal,
    updateApproveConfirmationModal,
    updateRejectConfirmationModal,
    handleUpdateStatus,
  } = useAffiliateStatus();
  const { openAddAffiliateModal } = useShowAddAffiliateModal();

  const { getBulkActions } = useBulkAffiliateRequestsActions();
  const { formValues } = useAffiliateSettingsModal();
  const {
    handleRowsSelected,
    selectedRecords,
    selectAll,
    handleSelectAll,
    currentPageFullSelection,
  } = useBulkActions(props);

  const { selectedIds, total, filterValues } = useListController({
    ...props,
    perPage: DEFAULT_PER_PAGE,
  });

  useEffect(() => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__FETCH_AFFILIATE_SETTINGS,
    });
  }, []);

  if (is_empty(formValues)) {
    return <ExlyLoader />;
  }

  const emptyStateProps =
    is_empty(total) && formValues?.disable_auto_approval === false
      ? {
          children: <AutoApprovedEmptyState />,
        }
      : {
          empty_list_icon: getS3AssetCDNUrl(
            "assets/images/affiliates/affliate_empty.svg"
          ),
          alt: "No affiliates yet.",
          content_title: "No affiliates yet",
          children:
            "Start adding affiliates to oversee their management and transactions",
        };
  const footerProps = {
    primarybtn: "Add Affiliate",
    primaryClick: openAddAffiliateModal,
  };

  return (
    <>
      <AffiliateManagementHeader />
      <div className={`px-3 px-md-4`}>
        {!is_empty(total) && formValues?.disable_auto_approval === false && (
          <AutoApprovedStrip />
        )}
        <ExlyTable
          allValuesSelected={selectAll}
          entityName={AFFLIATE_ENTITY_TYPE.affiliate.label}
          ra_props={{
            ...props,
          }}
          bulkActionComponent={
            <SelectionCard
              actions={Object.values(
                getBulkActions({
                  selectedRecords,
                  isSelectAll: selectAll,
                  filterValues,
                  clearSelection: () => handleSelectAll(false),
                })
              )}
              selectedRecords={Object.keys(selectedRecords)}
              totalRecords={total}
              selectAll={selectAll}
              handleSelectAll={handleSelectAll}
              currentPageFullSelection={currentPageFullSelection}
              entityName={"request"}
            />
          }
          noExportButton={true}
          tableTitle={<AffiliateManagementMobileHeader />}
          layoutProps={{
            paddingDesktop: "0",
            paddingMobile: "0",
            paddingBottom: "120px",
            noMobileBoxShadow: true,
          }}
          columnConfig={newAffilatesRequestColumnConfig}
          borderedFields={true}
          fieldsAlignment="space-between"
          renderSecondaryAction={() => <span>Actions</span>}
          customPrimaryAction={{
            renderCustomAction: (record) => {
              return (
                <div className={moduleStyle.actionsWrapper}>
                  <AffiliateStatusField
                    record={record}
                    affliateStatusDropdown={Object.values(AFFLIATE_STATUS)}
                    tag="affiliate_status"
                    tableName={BULK_ACTION_TABLES.AFFLIATES}
                    actionType={BULK_ACTION_TYPES.STATUS}
                  />
                </div>
              );
            },
          }}
          tableChipsFormatter={pendingTableChipsFormatter()}
          tableFilters={newAffliateFilterConfig}
          recordFooterVariant="secondary"
          {...emptyStateProps}
          tabConfig={Object.values(AFFILIATE_MANAGEMENT_TABS)}
          selectedTab={AFFILIATE_MANAGEMENT_TABS.new_affiliate.value}
          footerProps={footerProps}
          hasMobileFooter={true}
          checkboxSelection
          onRowSelected={handleRowsSelected}
          selected={selectedIds}
          primaryKey="id"
          showSelectAllcheckBox={true}
          {...affiliate_management_back_action}
        />
        {showRejectionConfirmationModal && (
          <ExlyDeleteModal
            open={showRejectionConfirmationModal}
            onClose={() => updateRejectConfirmationModal(false)}
            onDelete={() =>
              handleUpdateStatus({
                profileStatus: AFFLIATE_STATUS.rejected.value,
              })
            }
            title="Affiliate Rejection"
            description="Are you sure you want to reject the affiliate?"
            deleteBtnText="Reject"
          />
        )}
        {showApprovalSuccessModal && (
          <SuccessConfirmationModal
            show={showApprovalSuccessModal}
            onClose={() => updateApproveConfirmationModal(false)}
            handleSuccess={() =>
              handleUpdateStatus({
                profileStatus: AFFLIATE_STATUS.approved.value,
              })
            }
            title="Affiliate Approval"
            description="Are you sure you want to approve the affiliate?"
          />
        )}
      </div>
    </>
  );
});
