import React from "react";
import { NEW_AFFLIATE_STATUS, form_data } from "./newAffliates.constants";
import { NameField } from "ui/pages/customers/AllCustomers/components/TableColumns/TableColumns";
import { getDateInMonthDayYearTimeFormat } from "features/Common/DateFns.utils";
import moduleStyle from "../NewAffliateRequest.module.css";
import {
  BULK_ACTION_TABLES,
  BULK_ACTION_TYPES,
} from "features/BulkActions/BulkActions.constants";
import { AffiliateStatusField } from "../modules/AffliateStatusField/AffiliateStatusField";
import EmailField from "ui/Fields/EmailField";
import { WhatsAppPhoneField } from "common/Components/TableFields/TableFields";

export const newAffilatesRequestColumnConfig = [
  {
    field: form_data.title.key,
    headerName: form_data.title.label,
    emptyField: "-",
    sortable: false,
    width: "200px",
    isPrimary: true,
    avatar: true,
    valueFormatter: (record) => (
      <NameField
        record={record}
        source={form_data.title.key}
        className={{ name_field: moduleStyle.name_field }}
      />
    ),
  },
  {
    field: form_data.phone_number.key,
    headerName: form_data.phone_number.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => (
      <WhatsAppPhoneField
        record={record}
        source="phone_number"
        countryCodeKey="country_code"
      />
    ),
  },
  {
    field: form_data.email.key,
    headerName: form_data.email.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) =>
      record.email ? (
        <EmailField record={record} source={form_data.email.key} />
      ) : (
        "-"
      ),
  },
  {
    field: form_data.signup_date.key,
    headerName: form_data.signup_date.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) =>
      getDateInMonthDayYearTimeFormat(record.signup_date),
  },
  {
    field: "",
    headerName: "Action",
    valueFormatter: (record) => (
      <AffiliateStatusField
        record={record}
        tag={form_data.affiliate_status.key}
        tableName={BULK_ACTION_TABLES.AFFLIATES}
        actionType={BULK_ACTION_TYPES.STATUS}
      />
    ),
    sortable: false,
    noWrap: true,
    hidden: true,
  },
];
export const pendingTableChipsFormatter = () => (filter, value) => {
  switch (filter) {
    case "status": {
      const status = NEW_AFFLIATE_STATUS?.find((item) => item.value === value);
      return `Rejected: ${status?.label}`;
    }
    case `${form_data.title.key}`:
      return `Name: ${value}`;
    case `${form_data.email.key}`:
      return `Email: ${value}`;
    case `${form_data.phone_number.key}`:
      return `Phone: ${value}`;
    case `${form_data.signup_date.key}`:
      return `Sign up on or after: ${value}`;
    default:
      return undefined;
  }
};
export const newAffliateFilterConfig = [
  {
    source: form_data.title.key,
    placeholder: "Affiliate Name",
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.phone_number.key,
    placeholder: "Phone",
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.email.key,
    placeholder: "Email",
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.signup_date.key,
    placeholder: "Sign up date on or after",
    type: "date",
    disableContains: true,
  },
  {
    source: form_data.rejected.key,
    placeholder: form_data.rejected.label,
    type: "dropdown",
    options: NEW_AFFLIATE_STATUS,
    disableContains: true,
  },
];
