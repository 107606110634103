export const form_data = {
  title: {
    key: "full_name",
    label: "Affiliate Name",
  },
  phone_number: {
    key: "phone_number",
    label: "Phone",
  },
  email: {
    key: "email",
    label: "Email",
  },
  signup_date: {
    key: "signup_date",
    label: "Sign Up Date",
  },
  country_code: {
    key: "country_codee",
    label: "Country code",
  },
  rejected: {
    key: "status",
    label: "Rejected",
  },
  affiliate_status: {
    key: "affiliate_status",
  },
};

export const NEW_AFFLIATE_STATUS = [
  {
    label: "Yes",
    value: 3,
  },
  {
    label: "No",
    value: 1,
  },
];
