import React from "react";
import ExlyModal from "common/Components/ExlyModal";
import moduleStyle from "./DiscountSuccessModal.module.css";
import { useNotifications } from "utils/hooks";
import { ExlyInput } from "common/form";
import { Button } from "@my-scoot/component-library-legacy";
import { copyLink } from "features/AffiliateMarketing/utils/copyLink";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useShowDiscountCodesModal } from "../../AffliateDiscount/utils/useShowDiscountCodesModal";
import { navigateTo } from "utils/urlUtils";
import { AFFILIATE_MARKETING_URL_KEYS } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";
import ExlyImage from "features/Common/modules/ExlyImage/ExlyImage";

export const DiscountSuccessModal = ({ affiliateUuid }) => {
  const { notify } = useNotifications();
  const isDesktop = useDesktopMediaQuery();
  const { showDiscountCodeSuccessModal, showSuccessModal, selectedRecord } =
    useShowDiscountCodesModal();
  const { affiliateUuid: affiliateId } = useGetAffiliateUuid();

  const onClose = () => showDiscountCodeSuccessModal({ value: false });
  return (
    <ExlyModal
      open={showSuccessModal}
      title="Code created!"
      customHeader={isDesktop ? <></> : ""}
      onClose={onClose}
      customFooter={isDesktop ? <></> : ""}
      onPrimaryBtnClick={onClose}
      showSecondaryBtn={true}
      primaryBtnText={isDesktop ? "" : "Continue"}
      secondaryBtnText={isDesktop ? "" : "View Discount Codes"}
      secondaryBtnProps={{
        color: "secondary",
        variant: "outlined",
        buttonWrapperClassName: moduleStyle.secondaryButton,
        className: moduleStyle.secondaryButton,
      }}
      primaryBtnProps={{
        buttonWrapperClassName: moduleStyle.secondaryButton,
        className: moduleStyle.secondaryButton,
      }}
      modal_props={{
        modalPaperClassName: moduleStyle.desktopModalPaper,
        footerBtnsPosition: moduleStyle.footerBtn,
      }}
      mobile_modal_props={{ footerClassName: moduleStyle.footerClassName }}
      className={`p-0`}
    >
      <div className={moduleStyle.wrapper}>
        <div className={moduleStyle.container}>
          <ExlyImage
            path="assets/images/affiliates/discount_success.svg"
            className={moduleStyle.discountImg}
          />
          <div className={moduleStyle.rightWrapper}>
            <span className={moduleStyle.discountCode}>
              Discount Code Created!
            </span>
            <span className={moduleStyle.text}>
              Share this code with your affiliate!
            </span>
            <div className={moduleStyle.buttonWrapper}>
              <ExlyInput
                value={selectedRecord.code}
                innerWrapperClassName={moduleStyle.input}
                wrapperClassName={moduleStyle.discountInput}
                inputClassName={moduleStyle.discountInput}
                disabled={true}
                inputElementClassName={moduleStyle.disabled}
              />
              <Button
                color="secondary"
                className={moduleStyle.copyLink}
                variant="outlined"
              >
                <ExlyImage
                  path="assets/images/affiliates/content_copy_outlined.svg"
                  alt="Copy link"
                  onClick={() => {
                    copyLink(selectedRecord.code, notify);
                  }}
                  className={moduleStyle.affiliateCopyIcon}
                />
              </Button>
            </div>
          </div>
        </div>
        {isDesktop && (
          <div className={moduleStyle.buttonWrapper}>
            <Button
              color="secondary"
              className={moduleStyle.secondaryButton}
              buttonWrapperClassName={moduleStyle.buttonWrapperClassName}
              variant="outlined"
              onClick={() => {
                navigateTo(AFFILIATE_MARKETING_URL_KEYS.discount_codes, {
                  args: affiliateUuid ?? affiliateId,
                });
                onClose();
              }}
            >
              View Discount Codes (
              {selectedRecord.total_number_of_discount_codes})
            </Button>
            <Button
              color="primary"
              className={moduleStyle.secondaryButton}
              buttonWrapperClassName={moduleStyle.buttonWrapperClassName}
              onClick={() => onClose()}
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    </ExlyModal>
  );
};
