import { ExlyDropdown, ExlyInput, ExlyOfferingSelect } from "common/form";
import React from "react";
import styles from "./AddAffliateDiscount.module.css";
import { Field, useForm, useFormState } from "react-final-form";
import { required } from "features/Crm/modules/AddCustomerModal/utils/AddCustomer.validations";
import { getUserCurrency, isInternationalTimezone } from "utils/AuthUtil";
import {
  AFFLIATE_APPLICABILITY,
  AFFLIATE_DISCOUNT_CURRENCY,
  AFFLIATE_DISCOUNT_TYPE,
} from "../constants/affliateDiscount.constant";
import {
  validateDiscountCode,
  validateDiscountValue,
  validateUsageLimit,
} from "../utils/discount.utils";
import { useAffiliateDiscount } from "../../AffliateDiscount/utils/useAffiliateDiscount";
import ExlyCheckbox from "common/form/ExlyCheckbox";
import { form_data } from "../../AffliateDiscount/constants/affliateDiscount.constants";
import ExlyLoader from "ui/modules/ExlyLoader";

const AddAffliateDiscount = () => {
  const { values } = useFormState();
  const form = useForm();
  const { offeringsOptions } = useAffiliateDiscount();

  if (offeringsOptions === undefined) return <ExlyLoader />;

  return (
    <div className={styles.contentWrap}>
      <Field
        name={form_data.type.key}
        component={ExlyDropdown}
        size="small"
        fullWidth
        dropdownLabel={{
          label: "Type",
          size: "small",
          weight: "400",
        }}
        options={AFFLIATE_DISCOUNT_TYPE}
        onChange={() => {
          form.change("currency", getUserCurrency());
        }}
      />

      <Field
        name={form_data.value.key}
        component={ExlyInput}
        fullWidth
        type="number"
        size="small"
        label="Discount Value"
        placeholder="Enter discount value"
        labelClassName="font_400"
        inputElementClassName={styles.input}
        validate={(value) => validateDiscountValue(value, values.type)}
      />

      <Field
        name={form_data.code.key}
        component={ExlyInput}
        fullWidth
        label="Discount Code"
        placeholder="Enter Discount Code eg. EXLYAFFL50"
        size="small"
        format={(value) => value?.toUpperCase()}
        parse={(value) => value?.toUpperCase()}
        validate={validateDiscountCode}
        inputElementClassName={styles.input}
        labelClassName="font_400"
      />

      {!isInternationalTimezone() && values.type == 2 && (
        <Field
          component={ExlyDropdown}
          name={form_data.currency.key}
          fullWidth
          size="small"
          dropdownLabel={{
            label: "Currency",
            size: "small",
            weight: "400",
          }}
          labelClassName="font_400"
          options={AFFLIATE_DISCOUNT_CURRENCY}
        />
      )}
      <Field
        name={form_data.usage_limit.key}
        component={ExlyInput}
        fullWidth
        type="number"
        label="Usage Limit"
        placeholder="Enter Usage Limit"
        size="small"
        validate={validateUsageLimit}
        labelClassName="font_400"
        inputElementClassName={styles.input}
      />
      <div className={styles.mb}>
        <Field
          name={form_data.applicability.key}
          validate={required}
          size="small"
          component={ExlyDropdown}
          fullWidth
          placeholder="Select Offering"
          dropdownLabel={{
            label: "Applicability of this Discount Code",
            size: "small",
          }}
          options={AFFLIATE_APPLICABILITY}
        />
      </div>

      {values.applicability === AFFLIATE_APPLICABILITY[1].value && (
        <div className={styles.mb}>
          <Field
            name={form_data.listing_uuids.key}
            validate={required}
            component={ExlyOfferingSelect}
            label="Select specific offerings"
            multiple={true}
            showSelectAll
            mobileModalTitle="Select Offerings"
            placeholder="Select Offerings"
            selectAllContent="Select All"
            deselectAllContent="Remove All"
            options={offeringsOptions}
            fullWidth
            showStatus
            classes={{ label: "font_400" }}
          />
        </div>
      )}

      {!isInternationalTimezone() && (
        <Field
          name={form_data.include_gst.key}
          component={ExlyCheckbox}
          id="confirmGST"
          size="large"
          label="Include the price in GST"
          checked={values.include_gst}
          onChange={(checked) => form.change("include_gst", checked)}
        />
      )}
    </div>
  );
};
export default AddAffliateDiscount;
