import React from "react";
import {
  initiateBulkAction,
  performBulkAction,
} from "features/BulkActions/utils/BulkActions.utils";
import { useState } from "react";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";
import {
  BULK_ACTION_TABLES,
  BULK_ACTION_TYPES,
} from "features/BulkActions/BulkActions.constants";
import { AFFLIATE_STATUS } from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import moduleStyle from "../NewAffliateRequest.module.css";
import CancelIcon from "@material-ui/icons/Cancel";
import { useRefresh } from "react-admin";
import { isRequestSuccessful } from "utils/Utils";

export const useBulkAffiliateRequestsActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { notify } = useNotifications();
  const refetch = useRefresh();

  const getBulkActions = ({
    selectedRecords,
    filterValues,
    isSelectAll,
    clearSelection,
  }) => ({
    approve: {
      onClick: () => {
        handleBulkAction({
          selectedRecords,
          affiliate_status: AFFLIATE_STATUS.approved.value,
          filterValues,
          isSelectAll,
          clearSelection,
        });
      },
      text: "Approve",
      icon: <CheckCircleOutlineIcon className={moduleStyle.bulkApproveIcon} />,
      customClass: {
        label: moduleStyle.acceptBtn,
        wrapper: moduleStyle.acceptWrapper,
      },
    },
    reject: {
      onClick: () => {
        handleBulkAction({
          selectedRecords,
          affiliate_status: AFFLIATE_STATUS.rejected.value,
          filterValues,
          isSelectAll,
          clearSelection,
        });
      },
      text: "Reject",
      icon: <CancelIcon className={moduleStyle.bulkRejectIcon} />,
      customClass: {
        label: moduleStyle.rejectBtn,
        wrapper: moduleStyle.rejectWrapper,
      },
    },
  });

  const handleBulkAction = async ({
    selectedRecords,
    affiliate_status,
    filterValues,
    isSelectAll = true,
    clearSelection,
  }) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const { data, status } = await initiateBulkAction({
        bulkActionTable: BULK_ACTION_TABLES.AFFLIATES_REQUESTS,
        actionType: BULK_ACTION_TYPES.AFFLIATES_REQUESTS_STATUS_UPDATE,
        selectedIds: Object.keys(selectedRecords),
        actionData: { affiliate_status },
        filterData: filterValues,
        selectAll: isSelectAll,
      });

      if (isRequestSuccessful(status)) {
        const requestId = data?.request_id;
        if (!requestId) return;

        const { status: performBulkActionStatus, message } =
          await performBulkAction({ requestId });

        if (isRequestSuccessful(performBulkActionStatus)) {
          notify(message, notification_color_keys.success);
          refetch();
          clearSelection();
          return { status };
        }
      }
      return { status };
    } catch (err) {
      logError({
        err,
        occuredAt:
          "useBulkAffliateApproval in src/features/BulkActions/utils/hooks/useBulkAffliateApproval.js",
        when: "calling handleBulkAction",
      });
      notify(err?.message, notification_color_keys.error);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleBulkAction, getBulkActions };
};
