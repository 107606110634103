import React from "react";
import moduleStyle from "./InvoiceField.module.css";
import useInvoiceViewer from "features/AffiliateMarketing/utils/useInvoiceViewer";
import { is_empty } from "utils/validations";
import DownloadIcon from "@material-ui/icons/GetApp";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";

export const InvoiceField = ({ record, source = "receipt_url" }) => {
  const { getInvoiceURL, loading } = useInvoiceViewer();
  const isDesktop = useDesktopMediaQuery();

  return (
    <div
      onClick={() => getInvoiceURL(record?.[source])}
      className={`${moduleStyle.cta} pointer mx-2`}
    >
      {is_empty(record?.[source]) ? (
        "-"
      ) : (
        <div className={`d-flex align-items-center`}>
          {!isDesktop && (
            <DownloadIcon className={`mr-1 ${moduleStyle.icon}`} />
          )}
          {loading ? "..." : "Download Invoice"}
        </div>
      )}
    </div>
  );
};
