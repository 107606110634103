import { checkIsAlphaNumeric } from "features/Common/modules/Form/utils/Form.validations.utils";
import { is_empty, is_text_without_space } from "utils/validations";
import { DISCOUNT_TYPE } from "../../AffliateDiscount/constants/affliateDiscount.constants";

export const validateDiscountCode = (discountCode) => {
  if (is_empty(discountCode)) return "Discount code can't be empty!";

  if (is_text_without_space(discountCode))
    return "You can't use blank spaces in the discount code.";

  if (!checkIsAlphaNumeric(discountCode))
    return "Only Alphanumeric characters are allowed";

  if (discountCode.length > 10)
    return "Discount code length can't be more than 10 characters";

  return null;
};
export const validateUsageLimit = (usageLimit) => {
  if (is_empty(usageLimit)) return "Usage limit can't be empty!";
  if (parseInt(usageLimit) < 1) return "Usage limit should be greater than 0.";
  return null;
};

export const validateDiscountValue = (discountValue, type) => {
  if (is_empty(discountValue)) return "Discount value can’t be empty!";
  if (
    discountValue < 1 ||
    (discountValue > 100 && type === DISCOUNT_TYPE.PERCENTAGE)
  )
    return "Discount Percentage should be between 1-100";
  if (isNaN(discountValue)) return "Value cannot be non numeric";
  return null;
};
