import React, { useEffect } from "react";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ExlyTable from "common/Components/ExlyTable";
import EmptyImg from "../../../../assets/images/salesAndMarketing/discountCodes.svg";
import {
  getAffilatesOfferingColumnConfig,
  offeringsFilterConfig,
  offeringsTableChipsFormatter,
} from "./constants/offering.config";
import { affiliates_detail_tab } from "../AffliateDetail/constants/detail.constants";
import { detail_tab_config } from "../AffliateDetail/constants/detailColumn.config";
import { logError } from "utils/error";
import useAffiliateProfile from "../AffliateDetail/utils/useAffliateProfile";
import { AffiliateDetailHeader } from "../AffiliateDetailHeader/AffiliateDetailHeader";
import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";
import { CopyAffiliateLinkButton } from "./modules/CopyAffiliateLinkButton/CopyAffiliateLinkButton";
import { affiliate_detail_back_action } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import { AffiliateDetailMobileHeader } from "../AffiliateDetailHeader/AffiliateDetailMobileHeader";

export const OfferingWiseReport = withComponentLibraryTheme((props) => {
  const { affiliateUuid } = useGetAffiliateUuid();
  const { fetchAffiliateProfile } = useAffiliateProfile();
  const emptyStateProps = {
    empty_list_icon: EmptyImg,
    alt: "No affiliate offerings.",
    content_title: "No offerings added yet",
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await fetchAffiliateProfile();
      } catch (err) {
        logError({
          err,
          occuredAt:
            "src/features/AffiliateMarketing/modules/AffliateDeatils/index.jsx",
          when: "fecthing fetchAffiliateProfile",
        });
      }
    };
    fetchProfile();
  }, []);

  return (
    <>
      <AffiliateDetailHeader />
      <div className={`px-3 px-md-4`}>
        <ExlyTable
          ra_props={{
            ...props,
          }}
          entityName={"report"}
          tableTitle={<AffiliateDetailMobileHeader />}
          layoutProps={{
            paddingDesktop: "0",
            paddingMobile: "0",
            paddingBottom: "120px",
            noMobileBoxShadow: true,
          }}
          {...emptyStateProps}
          renderSecondaryAction={() => <span>Action:</span>}
          customPrimaryAction={{
            renderCustomAction: (record) => (
              <CopyAffiliateLinkButton record={record} />
            ),
          }}
          tableChipsFormatter={offeringsTableChipsFormatter()}
          tableFilters={offeringsFilterConfig}
          columnConfig={getAffilatesOfferingColumnConfig()}
          borderedFields={true}
          fieldsAlignment="space-between"
          recordFooterVariant="secondary"
          tabConfig={detail_tab_config(affiliateUuid)}
          selectedTab={affiliates_detail_tab.earning_and_activity.value}
          noExportButton={true}
          subTabSelected={
            affiliates_detail_tab.earning_and_activity.subTabs
              .offering_wise_report.value
          }
          {...affiliate_detail_back_action}
        />
      </div>
    </>
  );
});
