import React from "react";
import { getDateInMonthDayYearFormat } from "features/Common/DateFns.utils";
import { PayoutStatusField } from "../modules/PayoutStatusField/PayoutStatusFIeld";
import { InvoiceField } from "../../FormFields/InvoiceField/InvoiceField";
import { PayoutAmountField } from "../modules/PayoutAmountField/PayoutAmountField";
import {
  COMMISSION_PAYOUT_STATUS,
  COMMISSION_PAYOUT_STATUS_KEYS,
} from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";

export const form_data = {
  affiliate_name: {
    key: "affiliate_name",
    label: "Affiliate",
  },
  payout_amount: {
    key: "payout_amount",
    label: "Payment amount",
  },
  reciept_url: {
    key: "reciept_url",
    label: "Invoice",
  },
  payout_status: {
    key: "payout_status",
    label: "Status",
  },
  min_payout_amount: {
    key: "min_payout_amount",
    label: "Min Payout amount",
  },
  max_payout_amount: {
    key: "max_payout_amount",
    label: "Max Payout amount",
  },
  payout_date__start_range: {
    key: "payout_date__start_range",
    label: "Processed on or after",
  },
  payout_date__end_range: {
    key: "payout_date__end_range",
    label: "Processed on or before",
  },
  payout_date: {
    key: "payout_date",
    label: "Processed on",
  },
};
export const PAYOUT_STATUS = {
  pending: {
    value: 1,
    name: "pending",
    displayName: "Pending",
  },
  processed: {
    value: 5,
    name: "processed",
    displayName: "Processed",
  },
  initiated: {
    value: 2,
    name: "initiated",
    displayName: "Initiated",
  },
  bankDetails: {
    value: 2,
    name: "bankDetails",
    displayName: "Missing Bank Details",
  },
  failed: {
    value: 3,
    name: "failed",
    displayName: "Failed",
  },
};

export const PAYOUT_STATUS_OPTIONS = [
  COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.payout_pending],
  COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.processed],
  COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.failed],
  COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.missing_bank_details],
];

export const getPayoutAmountConfig = () => ({
  field: form_data.payout_amount.key,
  headerName: form_data.payout_amount.label,
  emptyField: "-",
  sortable: false,
  valueFormatter: (record) => <PayoutAmountField record={record} />,
});

export const getPayoutDateConfig = () => ({
  field: form_data.payout_date.key,
  headerName: form_data.payout_date.label,
  emptyField: "-",
  sortable: false,
  noWrap: true,
  valueFormatter: (record) =>
    record.payout_date ? getDateInMonthDayYearFormat(record.payout_date) : "-",
});

export const getReceiptURLConfig = () => ({
  field: form_data.reciept_url.key,
  headerName: form_data.reciept_url.label,
  emptyField: "-",
  sortable: false,
  noWrap: true,
  isCollapsed: true,
  valueFormatter: (record) => <InvoiceField record={record} />,
});

export const getPayoutStatusConfig = () => ({
  field: form_data.payout_status.key,
  headerName: "Status",
  valueFormatter: (record) => (
    <PayoutStatusField
      record={record}
      source={form_data.payout_status.key}
      dropdown={PAYOUT_STATUS}
    />
  ),
  sortable: false,
  noWrap: true,
  align: "left",
});

export const getMinPayoutAmountFilterConfig = () => ({
  source: form_data.min_payout_amount.key,
  placeholder: form_data.min_payout_amount.label,
  type: "input",
  disableContains: true,
});
export const getMaxPayoutAmountFilterConfig = () => ({
  source: form_data.max_payout_amount.key,
  placeholder: form_data.max_payout_amount.label,
  type: "input",
  disableContains: true,
});

export const getPayoutStatusFilterConfig = () => ({
  source: form_data.payout_status.key,
  placeholder: form_data.payout_status.label,
  options: PAYOUT_STATUS_OPTIONS,
  type: "dropdown",
  disableContains: true,
});

export const getPayoutDateStartRangeFilterConfig = () => ({
  source: form_data.payout_date__start_range.key,
  placeholder: form_data.payout_date__start_range.label,
  type: "date",
  disableContains: true,
});
export const getPayoutDateEndRangeFilterConfig = () => ({
  source: form_data.payout_date__end_range.key,
  placeholder: form_data.payout_date__end_range.label,
  type: "date",
  disableContains: true,
});
