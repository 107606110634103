import React from "react";
import moduleStyle from "./AutoApprovedStrip.module.css";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";

export const AutoApprovedStrip = () => {
  return (
    <div
      className={`d-flex align-items-center ${moduleStyle.container} p-2 mb-3 mb-md-0 mt-0 mt-md-3`}
    >
      <CheckIcon className={`${moduleStyle.icon}`} />
      <div className={`${moduleStyle.text} ml-2`}>
        All upcoming affiliates will be automatically approved
      </div>
    </div>
  );
};
