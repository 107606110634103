import React from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { Dropdown } from "@my-scoot/component-library-legacy";
import moduleStyle from "./AffliateStatusField.module.css";
import { AFFLIATE_STATUS } from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import { useAffiliateStatus } from "../../utils/useAffiliateStatus";

export const AffiliateStatusField = ({ record }) => {
  const isDesktop = useDesktopMediaQuery();
  const { onChangeStatus } = useAffiliateStatus();

  return (
    <>
      <Dropdown
        placeholder="Affiliate Status"
        value={record?.profile_status}
        handleChange={(value) => {
          onChangeStatus(value, record);
        }}
        options={Object.values(AFFLIATE_STATUS)}
        width={isDesktop && "120px"}
        selectClassName={
          record?.profile_status === AFFLIATE_STATUS.rejected.value
            ? "text-danger"
            : ""
        }
        formControlClassName={moduleStyle.dropdownRoot}
        inputBaseRootClassName={moduleStyle.dropdownRoot}
      />
    </>
  );
};
