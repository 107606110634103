import React from "react";
import { form_data } from "./offering.constants";
import { CopyAffiliateLinkButton } from "../modules/CopyAffiliateLinkButton/CopyAffiliateLinkButton";
import { RUPEE_SYMBOL } from "features/Common/modules/Currency/constants/Currency.constants";
import { is_empty } from "features/Common/utils/common.utils";
import { roundOff } from "features/Common/modules/DataTypes/utils/numbers";

export const getAffilatesOfferingColumnConfig = () => [
  {
    field: form_data.offering_name.key,
    headerName: form_data.offering_name.label,
    emptyField: "N/A",
    sortable: true,
  },
  {
    field: form_data.total_visitors.key,
    headerName: form_data.total_visitors.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
  },
  {
    field: form_data.total_bookings.key,
    headerName: form_data.total_bookings.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
  },
  {
    field: form_data.total_revenue.key,
    headerName: form_data.total_revenue.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => (
      <span>
        {is_empty(record.total_revenue) || record.total_revenue === "0.00"
          ? "0"
          : `${RUPEE_SYMBOL}${roundOff(record.total_revenue, 2) || "-"}`}
      </span>
    ),
  },
  {
    field: form_data.total_commission.key,
    headerName: form_data.total_commission.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => (
      <span>
        {is_empty(record.total_commission) || record.total_commission === "0.00"
          ? "0"
          : `${RUPEE_SYMBOL}${roundOff(record.total_commission, 2) || "-"}`}
      </span>
    ),
  },
  {
    field: form_data.total_earnings.key,
    headerName: form_data.total_earnings.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => (
      <span>
        {is_empty(record.total_earnings) || record.total_earnings === "0.00"
          ? "0"
          : `${RUPEE_SYMBOL}${roundOff(record.total_earnings, 2) || "-"}`}
      </span>
    ),
  },
  {
    field: "",
    headerName: "Action",
    valueFormatter: (record) => <CopyAffiliateLinkButton record={record} />,
    sortable: false,
    noWrap: true,
    hidden: true,
    fixed: "right",
  },
];
export const offeringsFilterConfig = [
  {
    source: form_data.offering_name.key,
    placeholder: form_data.offering_name.label,
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.visitors.min,
    placeholder: `Min ${form_data.visitors.label}`,
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.visitors.max,
    placeholder: `Max ${form_data.visitors.label}`,
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.bookings.min,
    placeholder: `Min ${form_data.bookings.label}`,
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.bookings.max,
    placeholder: `Max ${form_data.bookings.label}`,
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.revenue.min,
    placeholder: `Min ${form_data.revenue.label}`,
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.revenue.max,
    placeholder: `Max ${form_data.revenue.label}`,
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.commission.min,
    placeholder: `Min ${form_data.commission.label}`,
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.commission.max,
    placeholder: `Max ${form_data.commission.label}`,
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.earnings.min,
    placeholder: `Min ${form_data.earnings.label}`,
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.earnings.max,
    placeholder: `Max ${form_data.earnings.label}`,
    type: "input",
    disableContains: true,
  },
];
export const offeringsTableChipsFormatter = () => (filter, value) => {
  switch (filter) {
    case "status":
      return null;
    case `${form_data.bookings.min}`:
      return `Min bookings: ${value}`;
    case `${form_data.bookings.max}`:
      return `Max Bookings: ${value}`;
    case `${form_data.offering_name.key}`:
      return `Offering name: ${value}`;
    case `${form_data.commission.min}`:
      return `Min commission: ${value}`;
    case `${form_data.commission.max}`:
      return `Max commission: ${value}`;
    case `${form_data.earnings.min}`:
      return `Min earnings: ${value}`;
    case `${form_data.earnings.max}`:
      return `Max earnings: ${value}`;
    case `${form_data.revenue.min}`:
      return `Min revenue: ${value}`;
    case `${form_data.revenue.max}`:
      return `Max revenue: ${value}`;
    case `${form_data.visitors.min}`:
      return `Min visitors: ${value}`;
    case `${form_data.visitors.max}`:
      return `Max visitors: ${value}`;
    default:
      return undefined;
  }
};
