export const form_data = {
  offering_name: {
    key: "offering_name",
    label: "Offering Name",
  },
  total_visitors: {
    key: "total_visitors",
    label: "Visitors",
  },
  total_bookings: {
    key: "total_bookings",
    label: "Bookings",
  },
  total_revenue: {
    key: "total_revenue",
    label: "Total Revenue",
  },
  total_commission: {
    key: "total_commission",
    label: "Total Commission",
  },
  total_earnings: {
    key: "total_earnings",
    label: "Earnings",
  },
  link: {
    key: "link",
    label: "Copy affiliate link",
  },
  visitors: {
    label: "Visitors",
    min: "min_visitors",
    max: "max_visitors",
  },
  bookings: {
    label: "Bookings",
    min: "min_bookings",
    max: "max_bookings",
  },
  earnings: {
    label: "Earnings",
    min: "min_earnings",
    max: "max_earnings",
  },
  revenue: {
    label: "Revenue",
    min: "min_revenue",
    max: "max_revenue",
  },
  commission: {
    label: "Commissions",
    min: "min_commission",
    max: "max_commission",
  },
};
