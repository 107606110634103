import constants from "constants/constants";

export const AFFLIATE_DISCOUNT_TYPE = [
  { label: "Amount", value: 2 },
  { label: "Percentage", value: 1 },
];
export const AFFLIATE_DISCOUNT_CURRENCY = [
  { label: "INR", value: constants.inr },
  { label: "USD", value: constants.usd },
];
export const AFFLIATE_APPLICABILITY = [
  { label: "All approved offerings of the affiliate", value: 1 },
  { label: "Specific approved offerings of the affiliate", value: 2 },
];
