import { AFFLIATE_STATUS } from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import {
  EXLY_AFFILIATE_MARKETING__AFFILIATE_MANAGEMENT__SET_SELECTED_AFFILIATE_RECORD,
  EXLY_AFFILIATE_MARKETING__AFFILIATE_MANAGEMENT__SHOW_MODAL,
} from "features/AffiliateMarketing/redux/Affliates.actions";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { updateAffiliateProfileCall } from "features/AffiliateMarketing/utils/AffiliateMarketing.apiCalls";
import { isRequestSuccessful } from "utils/Utils";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { logError } from "utils/error";
import { useRefresh } from "react-admin";
import { is_empty } from "utils/validations";

export const useAffiliateStatus = () => {
  const dispatch = useDispatch();
  const refetch = useRefresh();
  const { notify } = useNotifications();
  const affiliateManagement = useSelector(
    (state) => state.affiliateMarketing.affiliateManagement
  );

  const [processing, setProcessing] = useState(false);

  const updateRejectConfirmationModal = (value, record) => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__AFFILIATE_MANAGEMENT__SHOW_MODAL,
      payload: { showRejectionConfirmationModal: value },
    });
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__AFFILIATE_MANAGEMENT__SET_SELECTED_AFFILIATE_RECORD,
      payload: value && !is_empty(record) ? record : {},
    });
  };

  const updateApproveConfirmationModal = (value, record) => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__AFFILIATE_MANAGEMENT__SHOW_MODAL,
      payload: { showApprovalSuccessModal: value },
    });
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__AFFILIATE_MANAGEMENT__SET_SELECTED_AFFILIATE_RECORD,
      payload: value && !is_empty(record) ? record : {},
    });
  };

  const onChangeStatus = (value, record) => {
    if (value === AFFLIATE_STATUS.approved.value) {
      updateApproveConfirmationModal(true, record);
    } else if (value === AFFLIATE_STATUS.rejected.value) {
      updateRejectConfirmationModal(true, record);
    } else if (value === AFFLIATE_STATUS.pending.value) {
      handleUpdateStatus({
        affiliateUuid: record?.uuid,
        profileStatus: AFFLIATE_STATUS.pending.value,
      });
    }
  };

  const handleUpdateStatus = async ({
    affiliateUuid = affiliateManagement.selectedAffiliateRecord.uuid,
    profileStatus,
  }) => {
    if (processing || is_empty(affiliateUuid)) return;
    setProcessing(true);
    try {
      const response = await updateAffiliateProfileCall({
        affiliateUuid,
        updatedValues: { status: profileStatus },
      });
      if (isRequestSuccessful(response?.status)) {
        notify(
          "Affiliate Status updated successfully",
          notification_color_keys.success
        );
        refetch();
        if (profileStatus === AFFLIATE_STATUS.rejected.value) {
          updateRejectConfirmationModal(false);
        }
        if (profileStatus === AFFLIATE_STATUS.approved.value) {
          updateApproveConfirmationModal(false);
        }
      }
    } catch (error) {
      logError({
        error,
        occuredAt:
          "src/features/AffiliateMarketing/modules/NewAffiliateRequest/utils/useAffiliateStatus.js",
        handleUpdateStatus,
      });
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    } finally {
      setProcessing(false);
    }
  };

  return {
    showRejectionConfirmationModal:
      affiliateManagement.showRejectionConfirmationModal,
    showApprovalSuccessModal: affiliateManagement.showApprovalSuccessModal,
    updateApproveConfirmationModal,
    updateRejectConfirmationModal,
    onChangeStatus,
    handleUpdateStatus,
  };
};
