import React, { useEffect } from "react";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ExlyTable from "common/Components/ExlyTable";
import {
  getAffilatesPayoutColumnConfig,
  payoutFilterConfig,
  payoutTableChipsFormatter,
} from "./constants/payout.config";
import { affiliates_detail_tab } from "../AffliateDetail/constants/detail.constants";
import { detail_tab_config } from "../AffliateDetail/constants/detailColumn.config";
import EmptyImg from "../../../../assets/images/salesAndMarketing/discountCodes.svg";
import { logError } from "utils/error";
import useAffiliateProfile from "../AffliateDetail/utils/useAffliateProfile";
import { AffiliateDetailHeader } from "../AffiliateDetailHeader/AffiliateDetailHeader";
import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";
import { AffiliateDetailMobileHeader } from "../AffiliateDetailHeader/AffiliateDetailMobileHeader";
import { InvoiceField } from "../FormFields/InvoiceField/InvoiceField";
import { affiliate_detail_back_action } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import { useCheckAffiliateAccessible } from "features/AffiliateMarketing/utils/useCheckAffiliateAccessible";

export const PayoutSummary = withComponentLibraryTheme((props) => {
  useCheckAffiliateAccessible();
  const { affiliateUuid } = useGetAffiliateUuid();
  const { fetchAffiliateProfile } = useAffiliateProfile();

  const emptyStateProps = {
    empty_list_icon: EmptyImg,
    alt: "No payouts yet",
    content_title: "No payouts yet",
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await fetchAffiliateProfile();
      } catch (err) {
        logError({
          err,
          occuredAt:
            "src/features/AffiliateMarketing/modules/AffliateDeatils/index.jsx",
          when: "fecthing fetchAffiliateProfile",
        });
      }
    };
    fetchProfile();
  }, []);

  return (
    <>
      <AffiliateDetailHeader />
      <div className={`px-3 px-md-4`}>
        <ExlyTable
          ra_props={{
            ...props,
          }}
          tableTitle={<AffiliateDetailMobileHeader />}
          entityName={"record"}
          {...emptyStateProps}
          noExportButton
          layoutProps={{
            paddingDesktop: "0",
            paddingMobile: "0",
            paddingBottom: "120px",
            noMobileBoxShadow: true,
          }}
          customPrimaryAction={{
            renderCustomAction: (record) => <InvoiceField record={record} />,
          }}
          columnConfig={getAffilatesPayoutColumnConfig()}
          borderedFields={true}
          fieldsAlignment="space-between"
          recordFooterVariant="secondary"
          tabConfig={detail_tab_config(affiliateUuid)}
          tableFilters={payoutFilterConfig}
          selectedTab={affiliates_detail_tab.earning_and_activity.value}
          tableChipsFormatter={payoutTableChipsFormatter()}
          subTabSelected="payout_summary"
          {...affiliate_detail_back_action}
        />
      </div>
    </>
  );
});
